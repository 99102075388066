<template>
  <div class="container has-background-white-ter">
    <section class="hero is-medium is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-8-desktop">
              <h1 class="title is-2 is-spaced">
                Choose Your School!
              </h1>


            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section">
      <div class="columns is-centered">
        <div class="column is-3" v-for='orgData, domain in userData' :key="domain" >
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">{{ domain }}</p>
            </header>
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <img v-bind:src="user.photoURL" v-bind:alt="user.displayName"/>
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-4">{{ user.displayName }}</p>
                  <p class="subtitle is-6">{{ user.email }}</p>
                  <button @click="chooseDomain(domain)">Choose</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { toast } from 'bulma-toast'
const ls = require('local-storage')

//import api from 'helpers/apicalls';


export default {
  name: "SchoolChooser",

  mounted: function() {



    let domains = Object.keys(this.$store.state.currentUserDataNew?.domains ?? {});
    if ( domains.length === 1) {
        localStorage.setItem("currentDomain", JSON.stringify(domains[0]))
        this.$store.commit("setCurrentDomain", domains[0])
        this.$router.push("/home/")

    }
    else {
      this.$store.commit("clearCurrentDomain")
      localStorage.removeItem("currentDomain")
    }

    //setTimeout(this.SetDomain, 3000);
  },



  computed: {
    userData() {
      if (this.$store.state.currentUserDataNew) {
        return this.$store.state.currentUserDataNew.domains;
      }
      return {}
    }
  },

  data: function() {
    return {
      user: firebase.auth().currentUser,
      students: [],

    };
  },

  methods: {

    setDomain() {

      let a = JSON.parse(localStorage.getItem("currentDomain"), {});

      if (typeof(a) == "string" && a.length!=0) {
        return;
      }

      let domains = Object.keys(this.$store.state.currentUserDataNew?.domains ?? {});
      if ( domains.length === 1) {
        localStorage.setItem("currentDomain", JSON.stringify(domains[0]))
          this.$store.commit("setCurrentDomain", domains[0])
          this.$router.push("/home/")
      }
    },

    chooseDomain(domain) {
      if (this.$store.state.currentUserDataNew.domains[domain] === undefined) {
        toast({
          message: 'Sorry, you can\'t see that domain',
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          position: 'center'
        });
      }
      else {
        ls("currentDomain", domain)
        this.$store.commit("setCurrentDomain", domain)
        this.$router.push("/home/")
      }
    }


  },



};
</script>
